import React from "react";
import Button from "@material-ui/core/Button";
//REDUX
import {temaActions} from '../store/tema-slice';
import { useSelector, useDispatch } from "react-redux";

export default function SwitchFondo() {
  const idioma = useSelector((state) => state.idioma.idioma);
  const dispatch = useDispatch();
  var mensaje = "";
  var opcion1 = "";
  var opcion2 = "";

  const handleChangeClaro = () => {
    dispatch(temaActions.changeTema({color:true, listo: true}));
  };

  const handleChangeOscuro = () => {
    dispatch(temaActions.changeTema({color:false, listo: true}));
  };

  if(idioma === "Español"){
      mensaje = "Seleccione el color del tema.";
      opcion1 = "Claro";
      opcion2 = "Oscuro";

  } else {
      mensaje = "Select theme color.";
      opcion1 = "Light";
      opcion2 = "Dark";
  }
  
  return (
    <div>
      <p style={ {fontSize:'16px', fontFamily: 'Roboto', margin: "15px", color: "white"} } >{mensaje}</p>
      <Button
        variant="contained" 
        color="primary" 
        size="small"
        style={{marginLeft: "65px",  alignSelf: "center"}}
        onClick={handleChangeClaro}
      >
        {opcion1}
        </Button>
      <Button
        variant="contained" 
        color="primary" 
        size="small"
        style={{margin: "15px", alignSelf: "center"}}
        onClick={handleChangeOscuro}
      >
        {opcion2}
      </Button>
    </div>
  );
}
