import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import TranslateIcon from '@material-ui/icons/Translate';
import ThemeIcon from '@material-ui/icons/InvertColors';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import LockIcon from '@material-ui/icons/Lock';
import { useAuth0 } from '@auth0/auth0-react';
import SettingsIcon from '@material-ui/icons/Settings';
import { IconButton } from '@material-ui/core';
import { useSelector } from "react-redux";

// import * as Scroll from 'react-scroll';
// import { Link, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'

const useStyles = makeStyles((theme) => ({
    temaButton: {
    marginRight: theme.spacing(2),
    marginLeft: 'auto',
},
    idiomaButton: {
        marginRight: theme.spacing(2),
        marginLeft: 'auto',
    },
    lockButton: {
        marginRight: theme.spacing(3),
        marginLeft: 'auto',
    },
}));

export default function MenuConfig(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const colorFondo = useSelector((state) => state.tema.color);
    
const StyledMenu = withStyles({
    paper: {
        backgroundColor: colorFondo ? 'white' : '#192737',
        border: '1px solid #d3d4d5',
    },
})((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));

const StyledMenuItem = withStyles((theme) => ({
    root: {
        '&:hover': {
            backgroundColor: '#00acee',
            // backgroundColor: theme.palette.primary.main,
            // '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
            //     color: theme.palette.common.white,
            //     '&.Mui-selected': { color: "#ffffff", }
            // },
        },
    },
}))(MenuItem);



    const classes = useStyles();

    const { isAuthenticated, loginWithRedirect, logout } = useAuth0();
    var M0 = "";
    var M1 = "";
    var M2 = "";
    var M3 = "";

    if (props.i === "Español") {
        M0 = "Tema";
        M1 = "Idioma";
        M2 = "Iniciar sesión";
        M3 = "Cerrar sesión";
    }
    if (props.i === "English") {
        M0 = "Theme";
        M1 = "Language";
        M2 = "Log in";
        M3 = "Log out";
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const openSwitchIdioma = () => {
        setAnchorEl(null);
        props.cambiarIdioma();
    }

    const openSwitchTema = () => {
        setAnchorEl(null);
        props.cambiarTema();
    }

    return (

        <div>
            <Button
                aria-controls="customized-menu"
                aria-haspopup="true"
                color="primary"
                onClick={handleClick}
            >
                <SettingsIcon />
            </Button>
            <StyledMenu
                backgroundColor="#000"
                id="customized-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
            <StyledMenuItem
                onClick={openSwitchTema}>
                <IconButton
                    className={classes.temaButton}
                    edge="start"
                    color="primary"
                >
                    <ThemeIcon />
                </IconButton>
                <ListItemText primary={M0} />
            </StyledMenuItem>
                <StyledMenuItem
                    onClick={openSwitchIdioma}>
                    <IconButton
                        className={classes.idiomaButton}
                        edge="start"
                        color="primary"
                    >
                        <TranslateIcon />
                    </IconButton>
                    <ListItemText primary={M1} />
                </StyledMenuItem>
                {
                    !isAuthenticated && (
                        <StyledMenuItem 
                        disabled='true'
                        onClick={() => loginWithRedirect()}>
                            <IconButton
                                className={classes.lockButton}
                                edge="start"
                                color="primary"
                            >
                                <LockIcon />
                            </IconButton>
                <ListItemText primary={M2} />
                        </StyledMenuItem>
                    )
                }
                {
                    isAuthenticated && (
                        <StyledMenuItem  
                        disabled='true'
                        onClick={() => logout()}>
                            <IconButton
                                className={classes.lockButton}
                                edge="start"
                                color="primary"
                            >
                                <LockOpenIcon />
                            </IconButton>
                <ListItemText primary={M3} />
                        </StyledMenuItem>
                    )
                }
            </StyledMenu>
        </div>
    );
}