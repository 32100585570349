import React from "react";
import { Link } from "react-router-dom";
import Canva from "../canvas/Canva"
import "../components/Barra";
import Barra from "../components/Barra";
import SwitchIdioma from "../components/SwitchIdioma";
import SwitchFondo from "../components/SwitchFondo";

//REDUX
import { useSelector, useDispatch } from "react-redux";
import { temaActions } from "../store/tema-slice";
import { idiomaActions } from "../store/idioma-slice";

function NotFound(){

const dispatch = useDispatch();
const idioma = useSelector((state) => state.idioma.idioma);
const listoIdioma = useSelector((state) => state.idioma.listo);
const listoTema = useSelector((state) => state.tema.listo);
const colorFondo = useSelector((state) => state.tema.color);

function cambiarIdioma() {
  dispatch(idiomaActions.changeLanguage({ idioma: idioma, listo: false }));
}

function cambiarTema() {
  dispatch(temaActions.changeTema({ color: colorFondo, listo: false }));
}

function muestraApp() {
  if (idioma === "Español") {
    return (
      <div className={"App " + (listoIdioma ? "" : "opaque")}>
        <Canva />
        <div className="App-barra">
          <Barra
            cambiarTema={() => cambiarTema()}
            cambiarIdioma={() => cambiarIdioma()}
            i={idioma}
          />
        </div>
            <section className="App-header">
        <h1>404 - Not Found!</h1>
        <Link to="/">Go Home</Link>
        </section>
      </div>
    );
  }
}

return (
  <div>
    <div>{muestraApp()}</div>
    {listoIdioma ? (
      <></>
    ) : (
      <div className="contenedorIdioma">
        <SwitchIdioma className="idioma" position="fixed" />
      </div>
    )}
    {listoTema ? (
      <></>
    ) : (
      <div className="contenedorIdioma">
        <SwitchFondo className="idioma" position="fixed" />
      </div>
    )}
  </div>
);
    }

export default NotFound;
