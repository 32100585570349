import React from 'react';
import Popover from '@material-ui/core/Popover';
import { makeStyles } from '@material-ui/core/styles';
import goatie from '../img/Si.gif';

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacing(1.5),
    backgroundColor: "#6441a5",
        backgroundImage: "linear-gradient(0deg, #6441a5 0%, #B5FFFC 100%)",
  },
}));

export default function Popup(props) {
  const classes = useStyles();

  return (
    <div>
      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={props.show}
        anchorEl={props.show}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        disableScrollLock
      >
      <img src = {goatie} className = "miniGoatie" alt = "Goatie"/>
      <div className="speech-bubble-mini">
        <p className="popup-text">{props.mensaje}</p>
        </div>
      </Popover>
    </div>
  );
}
