import React, { useState, useEffect } from "react";
import "./App.css";
import "./components/Barra";
import Barra from "./components/Barra";
import SwitchIdioma from "./components/SwitchIdioma";
import SwitchFondo from "./components/SwitchFondo";
import Mision from "./components/Mision";
import Vision from "./components/Vision";
import Proyectos from "./components/Proyectos";
import LoadFace from "./components/LoadFace";
import Contacto from "./components/Contacto";
import Intro from "./components/Intro";
import Curriculum from "./components/Curriculum";
import styled, { keyframes } from "styled-components";
import { zoomIn } from "react-animations";
import Banner from "./components/Banner";
import C from "./img/C.png";
import Cfdireader from "./img/cfdireader.png";
import Hack from "./img/Hackify.png";
import BD from "./img/basedatos.png";
import Report from "./img/report.png";
import Parkplus from "./img/parkplus.png";
import Popup from "./components/Popup";
import Canva from "./canvas/Canva";
import "./canvas/canva.css";
import logo from "./img/villanosoft.png";
import Typography from "@material-ui/core/Typography";

//REDUX
import { useSelector, useDispatch } from "react-redux";
import { temaActions } from "./store/tema-slice";
import { idiomaActions } from "./store/idioma-slice";

const zoomInAnimation = keyframes`${zoomIn}`;

const ZoomInDiv = styled.div`
  animation: 4s ${zoomInAnimation};
`;

function App() {
  // const [idioma, setIdioma] = useState("Español");
  // const [listo, setListo] = useState(true);
  const [showMision, setShowMision] = useState(false);
  const [showVision, setShowVision] = useState(false);
  const [showM, setShowM] = useState(false);
  const [showV, setShowV] = useState(false);
  const dispatch = useDispatch();
  const idioma = useSelector((state) => state.idioma.idioma);
  const listoIdioma = useSelector((state) => state.idioma.listo);
  const listoTema = useSelector((state) => state.tema.listo);
  const colorFondo = useSelector((state) => state.tema.color);

  useEffect(() => {
    const lang = navigator.language;
    if (lang === "en-US") {
      dispatch(
        idiomaActions.changeLanguage({ idioma: "English", listo: true })
      );
    } else if (lang === "es-ES") {
      dispatch(
        idiomaActions.changeLanguage({ idioma: "Español", listo: true })
      );
    } else {
      dispatch(
        idiomaActions.changeLanguage({ idioma: "English", listo: true })
      );
    }
  }, [dispatch]);

  const handleMouseEnterM = (event) => {
    setShowM(true);
  };

  const handleCloseM = () => {
    setShowM(false);
  };

  const handleMouseEnterV = (event) => {
    setShowV(true);
  };

  const handleCloseV = () => {
    setShowV(false);
  };

  function handleShowMision() {
    setShowMision((prevShowMision) => !prevShowMision);
  }

  function handleShowVision() {
    setShowVision((prevShowVision) => !prevShowVision);
  }

  // function handleIdioma(idioma) {
  //   setIdioma(idioma);
  //   setListo(true);
  // }

  function cambiarIdioma() {
    dispatch(idiomaActions.changeLanguage({ idioma: idioma, listo: false }));
  }

  function cambiarTema() {
    dispatch(temaActions.changeTema({ color: colorFondo, listo: false }));
  }

  function muestraApp() {
    if (idioma === "Español") {
      return (
        <div className={"App " + (listoIdioma ? "" : "opaque")}>
          <Canva />
          <div className="App-barra">
            <Barra
              cambiarTema={() => cambiarTema()}
              cambiarIdioma={() => cambiarIdioma()}
              i={idioma}
            />
          </div>
          <br id="info"></br>
          <section className="App-header">
            <ZoomInDiv>
              <h3>INFORMACIÓN</h3>
            </ZoomInDiv>
          </section>
          <div className="App-intro">
            <div className="App-loadface">
              <LoadFace />
            </div>
            <Intro i={idioma} />
          </div>
          <br id="valores"></br>
          <section className="App-header">
            <ZoomInDiv>
              <h3>VALORES</h3>
            </ZoomInDiv>
          </section>
          <div className="App-valores">
            <input type="checkbox" id="btnControlM" />
            <label className="btn" for="btnControlM">
              <div
                className={`${"flip-card"} ${
                  showMision ? "" : "shake-animation"
                }`}
              >
                <div className="flip-card-inner">
                  <div className="flip-card-front">
                    <img
                      className="imagenM"
                      src="https://images.pexels.com/photos/270348/pexels-photo-270348.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260"
                      alt="Desarrollo de Software"
                      onClick={handleShowMision}
                      onMouseEnter={handleMouseEnterM}
                      onMouseLeave={handleCloseM}
                    />{" "}
                    {!showMision && (
                      <Popup
                        show={showM}
                        mensaje={"Click para conocer la misión de Villanosoft."}
                      />
                    )}
                  </div>
                  <div className="flip-card-back">
                    <Mision i={idioma} />
                  </div>
                </div>
              </div>
            </label>
            <input type="checkbox" id="btnControlV" />
            <label className="btn" for="btnControlV">
              <div
                className={`${"flip-card"} ${
                  showVision ? "" : "shake-animation"
                }`}
              >
                <div className="flip-card-inner">
                  <div className="flip-card-front">
                    <img
                      className="imagenV"
                      src="https://images.pexels.com/photos/5474295/pexels-photo-5474295.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940"
                      alt="Desarrollo de Software"
                      onClick={handleShowVision}
                      onMouseEnter={handleMouseEnterV}
                      onMouseLeave={handleCloseV}
                    />{" "}
                    {!showVision && (
                      <Popup
                        show={showV}
                        mensaje={"Click para conocer la visión de Villanosoft."}
                      />
                    )}
                  </div>
                  <div className="flip-card-back">
                    <Vision i={idioma} />
                  </div>
                </div>
              </div>
            </label>
          </div>
          <br id="proyectos"></br>
          <section className="App-header">
            <ZoomInDiv>
              <h3>PROYECTOS</h3>
            </ZoomInDiv>
          </section>
          <div className="contenedor-proyectos">
            <div className="App-proyectos">
              <Proyectos
                titulo="Cotizac"
                descripcion="Sistema de control de inventarios por medio de documentos base: cotización y orden de compra. Utilizando JavaFX y su base de datos en SQL Server."
                imagen={C}
                ruta="https://drive.google.com/file/d/12S968QROoEGbCshEBgNrfyD585xf6Kyr/view?usp=sharing"
                mensaje="Click para conocer más de "
              />
              <Proyectos
                titulo="CFDI_Reader"
                descripcion="Aplicación en Java utilizando Apache POI para facilitar el control de archivos xml provenientes de CFDI, generando un archivo de Excel con la información que el usuario desee extraer."
                imagen={Cfdireader}
                ruta="https://drive.google.com/file/d/1RNyui0bdPP_xutPn840XMpHiDuOyxg1e/view"
                mensaje="Click para conocer más de "
              />
              <Proyectos
                titulo="Hackify Challenge"
                descripcion="Hackatón de inteligencia artificial donde se trabajó en equipo utilizando diferentes herramientas como Tensorflow, Javascript y Python para su desarrollo."
                imagen={Hack}
                ruta="https://drive.google.com/file/d/1lHGnOV2XRKQGefapXnIcJb8eksBx5V8P/view"
                mensaje="Click para conocer más de "
              />
            </div>
            <div className="App-proyectos">
              <Proyectos
                titulo="Bases de Datos"
                descripcion="Implementación, mantenimiento y gestión de bases de datos. Otorgando soluciones  por medio de creación, manipulación y extracción de información en SQL."
                imagen={BD}
                ruta=""
                mensaje="Click para conocer más de "
              />
              <Proyectos
                titulo="Reportería"
                descripcion="Desarrollo de reportería con información de base de datos según necesidad del cliente, utilizando herramientas como Jasper Reports o Crystal Reports."
                imagen={Report}
                ruta=""
                mensaje="Click para conocer más de "
              />
              <Proyectos
                titulo="Parkplus"
                descripcion="Aplicación para estacionamiento que permite controlar disponibilidad, cobrar y generar ticket de entrada y recibo de cobro. Desarrollado en Java, SQL Server y iTextPDF."
                imagen={Parkplus}
                ruta="https://drive.google.com/file/d/1OHGUSA5MeDNaGK3svztrj7GJKBiAvAgi/view"
                mensaje="Click para conocer más de "
              />
            </div>
          </div>
          <br id="cv"></br>
          <section className="App-header" id="cv">
            <ZoomInDiv>
              <h3>CURRÍCULUM</h3>
            </ZoomInDiv>
          </section>
          <div className="contenedor-cv">
            <div className="App-cv">
              <Curriculum i={idioma} />
            </div>
          </div>
          <br id="contacto"></br>
          <section className="App-header">
            <ZoomInDiv>
              <h3>CONTACTO</h3>
            </ZoomInDiv>
          </section>
          <div className="App-contacto">
            <Contacto i={idioma} />
          </div>
          <footer className="App-banner">
            <Banner i={idioma} />
          </footer>
        </div>
      );
    }
    if (idioma === "English") {
      return (
        <div className={"App " + (listoIdioma ? "" : "opaque")}>
          <Canva />
          <div className={colorFondo ? "colorFondoClaro" : "colorFondoOscuro"}>
            <div className="App-barra">
              <Barra
                cambiarTema={() => cambiarTema()}
                cambiarIdioma={() => cambiarIdioma()}
                i={idioma}
              />
            </div>
            <br id="info"></br>
            <section className="App-header">
              <ZoomInDiv>
                <h3>INFORMATION</h3>
              </ZoomInDiv>
            </section>
            <div className="App-intro">
              <div className="App-loadface">
                <LoadFace />
              </div>
              <Intro i={idioma} />
            </div>
            <br id="valores"></br>
            <section className="App-header">
              <ZoomInDiv>
                <h3>VALUES</h3>
              </ZoomInDiv>
            </section>
            <div className="App-valores">
              <input type="checkbox" id="btnControlM" />
              <label className="btn" for="btnControlM">
                <div className="flip-card">
                  <div className="flip-card-inner">
                    <div className="flip-card-front">
                      <img
                        className="imagenM"
                        src="https://images.pexels.com/photos/270348/pexels-photo-270348.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260"
                        alt="Desarrollo de Software"
                        onClick={handleShowMision}
                        onMouseEnter={handleMouseEnterM}
                        onMouseLeave={handleCloseM}
                      />{" "}
                      {!showMision ? (
                        <Popup
                          show={showM}
                          mensaje={
                            "Click para conocer la misión de Villanosoft."
                          }
                        />
                      ) : undefined}
                    </div>
                    <div className="flip-card-back">
                      <Mision i={idioma} />
                    </div>
                  </div>
                </div>
              </label>
              <input type="checkbox" id="btnControlV" />
              <label className="btn" for="btnControlV">
                <div className="flip-card">
                  <div className="flip-card-inner">
                    <div className="flip-card-front">
                      <img
                        className="imagenV"
                        src="https://images.pexels.com/photos/5474295/pexels-photo-5474295.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940"
                        alt="Desarrollo de Software"
                        onClick={handleShowVision}
                        onMouseEnter={handleMouseEnterV}
                        onMouseLeave={handleCloseV}
                      />{" "}
                      {!showVision ? (
                        <Popup
                          show={showV}
                          mensaje={
                            "Click para conocer la visión de Villanosoft."
                          }
                        />
                      ) : undefined}
                    </div>
                    <div className="flip-card-back">
                      <Vision i={idioma} />
                    </div>
                  </div>
                </div>
              </label>
            </div>
            <br id="proyectos"></br>
            <section className="App-header">
              <ZoomInDiv>
                <h3>PROJECTS</h3>
              </ZoomInDiv>
            </section>
            <div className="contenedor-proyectos">
              <div className="App-proyectos">
                <Proyectos
                  titulo="Cotizac"
                  descripcion="Software to manage and control your inventory using base documents: Clients quotation and purchase order. Developed with JavaFX and database using SQL Server."
                  imagen={C}
                  ruta="https://drive.google.com/file/d/1W9P0qi6wq9q-cOVI4WUWoYKJ_E3rF5oT/view"
                  mensaje="Click to learn more about "
                />
                <Proyectos
                  titulo="CFDI_Reader"
                  descripcion="Java App using Apache POI to facilitate the control from the bills. You can generate Excel document with the information the user wants to extract from the XML archives."
                  imagen={Cfdireader}
                  ruta="https://drive.google.com/file/d/15_HzBlFhXKQ4Id6Wqvh9upj7ZWb8l1VY/view"
                  mensaje="Click to learn more about "
                />
                <Proyectos
                  titulo="Hackify Challenge"
                  descripcion="Artificial Intelligence team hackaton where I used different tools and languages as Tensorflow, Javascript and Python to develop a mole and skin cancer analizer web app."
                  imagen={Hack}
                  ruta="https://drive.google.com/file/d/1lHGnOV2XRKQGefapXnIcJb8eksBx5V8P/view"
                  mensaje="Click to learn more about "
                />
              </div>
              <div className="App-proyectos">
                <Proyectos
                  titulo="Databases"
                  descripcion="Database administration. I manage to create solutions to different client scenarios that can be solved by creation, manipulation, and extraction of information using SQL."
                  imagen={BD}
                  ruta=""
                  mensaje="Click to learn more about "
                />
                <Proyectos
                  titulo="Reports"
                  descripcion="Design and creation of reports from database information to satisfy the client needs in the analysis of their figures. Using tools as Jasper Reports or Crystal Reports."
                  imagen={Report}
                  ruta=""
                  mensaje="Click to learn more about "
                />
                <Proyectos
                  titulo="Parkplus"
                  descripcion="Java App for a local parking lot to facilitate their daily work, from parking space avaliability, charging, ticket and receipt control. Developed using Java, SQL Server and iTextPDF."
                  imagen={Parkplus}
                  ruta="https://drive.google.com/file/d/1kT9xg8x8cCUR4CByaXmlC3p_yBwSy4Ql/view"
                  mensaje="Click to learn more about "
                />
              </div>
            </div>
            <br id="cv"></br>
            <section className="App-header" id="cv">
              <ZoomInDiv>
                <h3>RESUME</h3>
              </ZoomInDiv>
            </section>
            <div className="contenedor-cv">
              <div className="App-cv">
                <Curriculum i={idioma} />
              </div>
            </div>
            <br id="contacto"></br>
            <section className="App-header">
              <ZoomInDiv>
                <h3>CONTACT</h3>
              </ZoomInDiv>
            </section>
            <div className="App-contacto">
              <Contacto i={idioma} />
            </div>
            <div className="App-banner">
              <Banner i={idioma} />
            </div>
          </div>
        </div>
      );
    }
  }

  const enMantenimiento = () => {
    return (
      <div className={"App " + (listoIdioma ? "" : "opaque")}>
      <Canva />
        <div className="logoCentrado">
          <ZoomInDiv>
            <img src={logo} className="logo" alt="villanosoft" />
          </ZoomInDiv>
          {idioma === "English" ? (
            <ZoomInDiv>
              <h3>UNDER MAINTENANCE</h3>
            </ZoomInDiv>
          ) : (
            <ZoomInDiv>
              <h3>EN MANTENIMIENTO</h3>
            </ZoomInDiv>
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      <>{enMantenimiento()}</>
      {listoIdioma ? (
        <></>
      ) : (
        <div className="contenedorIdioma">
          <SwitchIdioma className="idioma" position="fixed" />
        </div>
      )}
      {listoTema ? (
        <></>
      ) : (
        <div className="contenedorIdioma">
          <SwitchFondo className="idioma" position="fixed" />
        </div>
      )}
    </>
  );
}

export default App;
