import React, { useEffect } from "react";
import goatie from "../img/logo goaty.png";
import { useSelector } from "react-redux";
import "./canva.css";

const Canva = () => {
  const colorFondo = useSelector((state) => state.tema.color);

  // const mouseMove = () => {
  //   console.log("mouseMove");
  //   var x = Math.random();
  //   var y = Math.random();

  //   console.log(x, y);

  //   setGlobalVelX(x);
  //   setGlobalVelY(y);
  // };

  // const mouseOut = () => {
  //   console.log("mouseOut");
  //   setGlobalVelX(0);
  //   setGlobalVelY(0);
  // };

  useEffect(() => {

    function getRandomInt(min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    function cargarAnimacion() {
      
      //Create canvas and initialize it's context
      var canvas = document.getElementById("fondo");
      var ctx = canvas.getContext("2d");

      //Set the dimensions of canvas equal to the window's dimensions
      var W = window.innerWidth,
        H = window.innerHeight;
      canvas.width = W;
      canvas.height = H;

      //Create an array of circles
      var circles = [];
      for (var i = 0; i < 50; i++) {
        circles.push(new create_circle());
      }

      //Function to create circles with different positions and velocities
      function create_circle() {
        //Random Position
        this.x = Math.random() * W;
        this.y = Math.random() * H;

        //Random Velocities
        this.vx = 0.1 + Math.random() * 1;
        this.vy = -this.vx;

        this.size = getRandomInt(1, 70);

        this.isBlur = getRandomInt(0, 1) === 1;
        this.blurStrength = getRandomInt(2, 7);
      }

      //Function to draw the background
      function draw() {
        var newVelX, newVelY = 0;
        //Create the gradient
        var grad = ctx.createLinearGradient(0, 0, W, H);
        if (colorFondo === false) {
          grad.addColorStop(0, "rgb(0, 0, 0)");
          grad.addColorStop(1, "rgb(0, 0, 0)");
        }
        if (colorFondo === true) {
          grad.addColorStop(0, "rgb(255,255,255)");
          grad.addColorStop(1, "rgb(255,255,255)");
        }

        //Fill the canvas with the gradient
        ctx.globalCompositeOperation = "source-over";
        ctx.fillStyle = grad;
        ctx.fillRect(0, 0, W, H);

        var img = new Image();
        img.src = goatie;

        //Checar limites
        function thingRangeCheck(item) {
          if (item.x < 0) {
            item.x = canvas.width;
          } else if (item.x > canvas.width) {
            item.x = 0;
          }
          if (item.y < 0) {
            item.y = canvas.height;
          } else if (item.y > canvas.height) {
            item.y = 0;
          }
        }

        //Fill the canvas with the circles
        for (var j = 0; j < circles.length; j++) {
          var c = circles[j];
          //Draw the circle and it with the blur grad

          img.style = "blur(3px)";
          ctx.drawImage(img, c.x, c.y, c.size, c.size);
          // if (c.isBlur) {
          //   //tackBlurCanvasRGB('fondo', c.x, c.y, c.size,c.size, c.blurStrength);
          // }
          //Lets use the velocity now
          c.x += c.vx;
          c.y += c.vy;

          if (c.x > newVelX) {
            c.x -= c.vx * 2;
          } else {
            c.x += c.vx * 2;
          }

          if (c.y > newVelY) {
            c.y += c.vy * 2;
          } else {
            c.y -= c.vy * 2;
          }
          thingRangeCheck(c);
          //To prevent the circles from moving out of the canvas
        }
      }


      //YA NO USAR, SE PENSABA CAMBIAR LA DIRECCIÓN GLOBAL, SE CAMBIA DIRECCIÓN ESPECIFICA DE CADA OBJECTO
      //Cambiar de dirección de forma aleatoria
      // function cambiarDireccionX() {
      //   console.log("cambiarDireccion X");
      //   var x = getRandomInt(0,window.innerWidth);
      //   console.log(x);
      //   return x;
      // }

      // function cambiarDireccionY() {
      //   console.log("cambiarDireccion Y");
      //   var y = getRandomInt(0,window.innerHeight);
      //   console.log(y);
      //   return y;
      // }

      setInterval(draw, 25);
    }
    cargarAnimacion();
  }, [colorFondo]);

  return <canvas id="fondo" className={colorFondo ? "colorFondoClaro" : "colorFondoOscuro"}/>;
};

export default Canva;
