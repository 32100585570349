import React, { Component } from "react";
import { Document, Page } from "react-pdf";
import { pdfjs } from "react-pdf";
import urlEsp from "../../src/img/cv_esp.pdf";
import urlIng from "../../src/img/cv_eng.pdf";
import Popup from './Popup';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default class Pdf extends Component {
  state = {
    pageNumber: 1,
    file: urlEsp,
    file1: urlIng,
    show: false,
  };

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  };

  render() {
    const { pageNumber, file, file1, show } = this.state;

    const handleMouseEnter = (event) => {
      this.setState({show:true});
    };
  
    const handleClose = () => {
      this.setState({show:false});
    };

    if (this.props.i === "Español") {
      const mensaje = "Click para descargar currículum.";
      return (
        <div 
        onMouseEnter={handleMouseEnter} 
        onMouseLeave={handleClose}>
          <Document
            file={file} onLoadSuccess={this.onDocumentLoadSuccess}>
            <Page pageNumber={pageNumber} />
          </Document>
          <Popup show={show} mensaje={mensaje} />
        </div>
      );
    }

    if (this.props.i === "English") {
      const mensaje = "Click to download resume.";
      return (
        <div className="PDF" onMouseEnter={handleMouseEnter} onMouseLeave={handleClose}>
          <Document file={file1} onLoadSuccess={this.onDocumentLoadSuccess}>
            <Page pageNumber={pageNumber} />
          </Document>
        <Popup show={show} mensaje={mensaje} />
        </div>
      );
    }
  }
}
