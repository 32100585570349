import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Link from '@material-ui/core/Link';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Popup from './Popup';

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
    backgroundColor: "#6441a5",
        backgroundImage: "linear-gradient(0deg, #6441a5 0%, #B5FFFC 100%)",
    margin: '25px 0px 0px 25px',
    borderRadius: 16,
    boxShadow: 'none',
    '@media screen and (min-width: 650px)':{
    '&:hover': {
      transition: 'transform .2s',
      transform: 'scale(1.1)',
      opacity: '0.9',
    },
  },
    '@media screen and (max-width: 650px)':{
      '&:active':{
      transition: 'transform .2s',
      transform: 'scale(1.1)',
      opacity: '0.9',
      }
    },
  },
  media: {
      width: 100,
    height: 110,
    margin: '0px 0px 0px 120px',
  },
  subtitulo: {
    //fontSize: '1.1rem',
  },
});

export default function MediaCard(props) {
  const classes = useStyles();
  const [show, setShow] = useState(false);

  const preventDefault = (event) => event.preventDefault();

  const handleMouseEnter = (event) => {
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
  };

  return (
    <div className="Card" onMouseEnter={handleMouseEnter} onMouseLeave={handleClose}>
        <Card className={classes.root}
        >
        <CardActionArea
            href={props.ruta}
            target = "_blank">
            <CardMedia
            className={classes.media}
            image={props.imagen}
            title="Proyecto"
            />
            <CardContent>
            <Typography gutterBottom variant="h4" component="h4">
                {props.titulo}
            </Typography>
            <Typography className= {classes.subtitulo} variant="subtitle2" color="black" component="p">
                {props.descripcion}
            </Typography>
            </CardContent>
            <Link href={props.ruta} onClick={preventDefault}>
            </Link>
        </CardActionArea>
        <CardActions>
        </CardActions>
        </Card>
        <Popup show={show} mensaje={props.mensaje + props.titulo} />
    </div>
  );
}