import {createSlice} from '@reduxjs/toolkit';
const idiomaInStorage = JSON.parse(localStorage.getItem('miIdioma'));

const idiomaSlice = createSlice({
    name: 'idioma',
    initialState: idiomaInStorage || {idioma: 'English', listo: true},
    reducers:{
        changeLanguage(state,action){
            state.idioma = action.payload.idioma;
            state.listo = action.payload.listo;
            let miIdioma =  {color: state.color,listo: state.listo};
            localStorage.setItem('miIdioma',JSON.stringify(miIdioma));
        },
    }
});

export const idiomaActions = idiomaSlice.actions;

export default idiomaSlice;