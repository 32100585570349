import React from "react";
import Button from "@material-ui/core/Button";
//REDUX
import { useDispatch } from "react-redux";
import {idiomaActions} from '../store/idioma-slice';

export default function SwitchIdioma(props) {
  const dispatch = useDispatch();
  
  const handleChangeEsp = () => {
    dispatch(idiomaActions.changeLanguage({idioma:"Español",listo:true}));
  };

  const handleChangeEng = () => {
    dispatch(idiomaActions.changeLanguage({idioma:"English",listo:true}));
  };

  return (
    <div>
      <p style={ {fontSize:'16px', fontFamily: 'Roboto', margin: "15px", color: "white"} } >{'Seleccione idioma / Select language'}</p>
      <Button
        type="submit" 
        variant="contained" 
        color="primary" 
        size="small"
        style={{marginLeft: "65px",  alignSelf: "center"}}
        onClick={handleChangeEsp}
      >
        Español
        </Button>
      <Button
        type="submit" 
        variant="contained" 
        color="primary" 
        size="small"
        style={{margin: "15px", alignSelf: "center"}}
        onClick={handleChangeEng}
      >
        English
      </Button>
    </div>
  );
}
