import React, {useRef} from 'react';
import {AppBar,Toolbar,IconButton} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MenuBarra from './MenuBarra';
import MenuConfig from './MenuConfig';
import logo from '../img/villanosoft.png';
import { useSelector } from "react-redux";

//const logo  = 'https://drive.google.com/uc?export=view&id=1bTJxhZvUCSM1AY69kAmaQYTEJkrss_0Q';

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      position: 'relative',
    },
    settingsSection: {
      marginLeft: 'auto',
      marginRight: 0,
    },
    menuButton: {
      marginRight: theme.spacing(1),
    },
    menuButtonConfig: {
      marginLeft: theme.spacing(1),
    },
    title: {
      flexGrow: 1,
    },
    barraClara: {
      backgroundColor: "white",
    },
    barraOscura: {
      backgroundColor: "black",
    },
  }));

export default function Barra(props){
  const colorFondo = useSelector((state) => state.tema.color);

  const menuRef = useRef();

        const classes = useStyles();
            return(
                <div>
                    <AppBar 
                           className={(colorFondo ? classes.barraClara : classes.barraOscura)}
                           position="fixed" >
                        <Toolbar className="barra">
                          <div className="logoCentrado">
                            <img src = {logo} className  = "logo" alt = "villanosoft"/>
                          </div>
                          <IconButton 
                            ref = {menuRef}  
                            edge= "start"
                            className={classes.menuButton} 
                            color="primary" 
                          >
                            <MenuBarra
                            color="inherit"
                            i={props.i}
                            />
                          </IconButton>
                          <div className={classes.settingsSection}>
                          
                          <IconButton 
                            ref = {menuRef} 
                            edge= "end" 
                            className={classes.menuButtonConfig} 
                            color="primary" 
                          >
                            <MenuConfig
                            color="inherit"
                            i={props.i}
                            cambiarIdioma={() => props.cambiarIdioma()} cambiarTema = {()=> props.cambiarTema()}
                            />
                          </IconButton>
                          </div>
                        </Toolbar>
                    </AppBar>
                </div>
            );
}