import {createSlice} from '@reduxjs/toolkit';
const temaInStorage = JSON.parse(localStorage.getItem('miTema'));

const temaSlice = createSlice({
    name: 'tema',
    initialState: temaInStorage || {color: true, listo: true},
    reducers:{
        changeTema(state,action){
            state.color = action.payload.color;
            state.listo = action.payload.listo;
            let miTema =  {color: state.color,listo: state.listo};
            localStorage.setItem('miTema',JSON.stringify(miTema));
        },
    }
});

export const temaActions = temaSlice.actions;

export default temaSlice;